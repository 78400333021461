// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).
.dl-horizontal {
    @media (min-width: $grid-float-breakpoint) {
        dt,
        dd {
            padding: 3px 0;
        }
        dt {
            //width: ($dl-horizontal-offset - 20);
            text-align: left;
        }
        //dd {
        //    margin-left: $dl-horizontal-offset;
        //}
    }
}
