.navbar {
    font-family: $font-family-specials;
    letter-spacing: 2px;
}

.container,
.container-fluid {
    & > .navbar-header,
    & > .navbar-collapse {
        margin-left: 65px;

        @media (min-width: $screen-sm-min) {
            margin-left: 170px;
        }
        @media (min-width: 1400px) {
            margin-left: 110px;
        }
        @media (min-width: 1500px) {
            margin-left: 60px;
        }
        @media (min-width: 1625px) {
            margin-left: 0;
        }
    }
}

.navbar-brand sup {
    font-size: 75%;
    //line-height: 0;
    //vertical-align: baseline;
}

// Dropdowns
.navbar-nav {
    // Dropdowns get custom display
    .open .dropdown-menu {
        //> .dropdown-header {
        //    border-color: $navbar-inverse-border;
        //}
        //.divider {
        //    background-color: $navbar-inverse-border;
        //}
        //> li > a {
        //    color: $navbar-inverse-link-color;
        //    &:hover,
        //    &:focus {
        //        color: $navbar-inverse-link-hover-color;
        //        background-color: $navbar-inverse-link-hover-bg;
        //    }
        //}
        > .active > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-inverse-link-active-color;
                background-color: $navbar-inverse-link-active-bg;
            }
        }
        > .disabled > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-inverse-link-disabled-color;
                background-color: $navbar-inverse-link-disabled-bg;
            }
        }
    }
}
