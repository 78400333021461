//$bootstrap-sass-asset-helper: false;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

//$gray-base:              #000;
//$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              #666;
$gray-medium-dark:       #999;
$gray:                   #dedede;
$gray-light:             #eeece9;
//$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$brand-primary:         #00acca;
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

// Added by Jan-Christoph Ihrens:
$brand-primary-darker:  darken($brand-primary, 10%);


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #eeece9;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:    "Conv_DINOT-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:         Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:     Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:          $font-family-sans-serif;
$font-family-specials:      "Conv_Nexa Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-specials-bold: "Conv_Nexa Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base:          14px;
//$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
//$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px
//
//$font-size-h1:            floor(($font-size-base * 2.6)); // ~36px
//$font-size-h2:            floor(($font-size-base * 2.15)); // ~30px
//$font-size-h3:            ceil(($font-size-base * 1.7)); // ~24px
//$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
//$font-size-h5:            $font-size-base;
//$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    $font-family-specials;
$headings-font-weight:    normal;
$headings-line-height:    1.3;
//$headings-color:          inherit;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

//$btn-font-weight:                normal;

$btn-default-color:              darken($gray-dark, 10%);
$btn-default-bg:                 $gray;
$btn-default-border:             #ccc;

//$btn-primary-color:              #fff;
//$btn-primary-bg:                 $brand-primary;
//$btn-primary-border:             darken($btn-primary-bg, 5%);

//$btn-success-color:              #fff;
//$btn-success-bg:                 $brand-success;
//$btn-success-border:             darken($btn-success-bg, 5%);

//$btn-info-color:                 #fff;
//$btn-info-bg:                    $brand-info;
//$btn-info-border:                darken($btn-info-bg, 5%);

//$btn-warning-color:              #fff;
//$btn-warning-bg:                 $brand-warning;
//$btn-warning-border:             darken($btn-warning-bg, 5%);

//$btn-danger-color:               #fff;
//$btn-danger-bg:                  $brand-danger;
//$btn-danger-border:              darken($btn-danger-bg, 5%);

//$btn-link-disabled-color:        $gray-light;


//== Forms
//
//##

//** `<input>` background color
//$input-bg:                       #fff;
//** `<input disabled>` background color
//$input-bg-disabled:              $gray-lighter;

//** Text color for `<input>`s
$input-color:                    $gray-dark;
//** `<input>` border color
//$input-border:                   #ccc;

//** Border color for inputs on focus
//$input-border-focus:             #66afe9;

//** Placeholder text color
//$input-color-placeholder:        #999;

//** Default `.form-control` height
//$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
//$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-control` height
//$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

//$legend-color:                   $gray-dark;
//$legend-border-color:            #e5e5e5;

//** Background color for textual input addons
//$input-group-addon-bg:           $gray-lighter;
//** Border color for textual input addons
//$input-group-addon-border-color: $input-border;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    $brand-primary;
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color:            lighten($gray-light, 15%);
//** Hover color for dropdown links.
$dropdown-link-hover-color:      lighten($gray-light, 15%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         darken($dropdown-bg, 10%);

//** Active dropdown menu item text color.
//$dropdown-link-active-color:     $component-active-color;
//** Active dropdown menu item background color.
//$dropdown-link-active-bg:        $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000;


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    50px;
$navbar-margin-bottom:             2 * $line-height-computed;
$navbar-border-radius:             0;
//$navbar-padding-horizontal:        floor(($grid-gutter-width / 2));
//$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2);
//$navbar-collapse-max-height:       340px;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%);
$navbar-inverse-bg:                         $brand-primary;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%);
$navbar-inverse-link-hover-color:           #fff;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%);
//$navbar-inverse-link-disabled-color:        #444;
//$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
//$navbar-inverse-brand-color:                $navbar-inverse-link-color;
//$navbar-inverse-brand-hover-color:          #fff;
//$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
//$navbar-inverse-toggle-hover-bg:            #333;
//$navbar-inverse-toggle-icon-bar-bg:         #fff;
//$navbar-inverse-toggle-border-color:        #333;


//== Jumbotron
//
//##

//$jumbotron-padding:              30px;
//$jumbotron-color:                inherit;
$jumbotron-bg:                   #fff;
//$jumbotron-heading-color:        inherit;
//$jumbotron-font-size:            ceil(($font-size-base * 1.5));


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

//$state-success-text:             #3c763d;
//$state-success-bg:               #dff0d8;
//$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%);
//
//$state-info-text:                #31708f;
//$state-info-bg:                  #d9edf7;
//$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%);
//
$state-warning-text:             #a06633;  // #8a6d3b
$state-warning-bg:               #fff9e5;  // #fcf8e3
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 10%);

$state-danger-text:              #852020;  // #a94442
$state-danger-bg:                #f0cccc;  // #f2dede
//$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%);
$state-danger-border:            darken($state-danger-bg, 10%);


//== Tooltips
//
//##

//** Tooltip max width
//$tooltip-max-width:           200px;
//** Tooltip text color
//$tooltip-color:               #fff;
//** Tooltip background color
$tooltip-bg:                  darken($gray-dark, 10%);
//$tooltip-opacity:             .9;

//** Tooltip arrow width
//$tooltip-arrow-width:         5px;
//** Tooltip arrow color
//$tooltip-arrow-color:         $tooltip-bg;


//== Panels
//
//##

$panel-bg:                    #fff;
//$panel-body-padding:          15px;
//$panel-heading-padding:       10px 15px;
//$panel-footer-padding:        $panel-heading-padding;
//$panel-border-radius:         $border-radius-base;

//** Border color for elements within panels
//$panel-inner-border:          #ddd;
//$panel-footer-bg:             #f5f5f5;

//$panel-default-text:          $gray-dark;
$panel-default-border:        darken($gray, 15%);
$panel-default-heading-bg:    darken($gray, 5%);

//$panel-primary-text:          #fff;
//$panel-primary-border:        $brand-primary;
//$panel-primary-heading-bg:    $brand-primary;


//== Wells
//
//##

$well-bg:                     #fff;
//$well-border:                 darken($well-bg, 7%);


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   0;
$breadcrumb-padding-horizontal: 0;
//** Breadcrumb background color
$breadcrumb-bg:                 transparent;
//** Breadcrumb text color
$breadcrumb-color:              $gray-medium-dark;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-medium-dark;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          ">";


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 200px;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal;
