@font-face {
    font-family: 'Conv_Nexa Light';
    src: url('../fonts/Nexa_Light.eot');
    src: local('â˜º'), url('../fonts/Nexa_Light.woff') format('woff'), url('../fonts/Nexa_Light.ttf') format('truetype'), url('../fonts/Nexa_Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_Nexa Bold';
    src: url('../fonts/Nexa_Bold.eot');
    src: local('â˜º'), url('../fonts/Nexa_Bold.woff') format('woff'), url('../fonts/Nexa_Bold.ttf') format('truetype'), url('../fonts/Nexa_Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_DINOT-Regular';
    src: url('../fonts/DINOT-Regular.eot');
    src: local('â˜º'), url('../fonts/DINOT-Regular.woff') format('woff'), url('../fonts/DINOT-Regular.ttf') format('truetype'), url('../fonts/DINOT-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
.dinot {
    font-family: 'Conv_DINOT-Regular';
}
.nexa_light {
    font-family: 'Conv_Nexa Light';
}
.nexa_bold {
    font-family: 'Conv_Nexa Bold';
}

.nowrap {
    white-space: nowrap;
}
