/**
 * DataTables
 */

// Avoid displaying two different sorter graphics when using DataTables
table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
    //content: '' !important;
    content: none !important;
}
td.details-control {
    background: url('../images/button_rounded_add.png') no-repeat center 11px;
    cursor: pointer;
}
tr.shown td.details-control {
    background: url('../images/button_rounded_remove.png') no-repeat center 11px;
}
table.dataTable thead th {
    vertical-align: middle;
}
table.dataTable thead .sorting {
    background-image: url("../js/vendor/datatables/media/images/sort_both.png") !important;
}
table.dataTable thead .sorting_asc {
    background-image: url("../js/vendor/datatables/media/images/sort_asc.png") !important;
}
table.dataTable thead .sorting_desc {
    background-image: url("../js/vendor/datatables/media/images/sort_desc.png") !important;
}
table.dataTable thead .sorting_asc_disabled {
    background-image: url("../js/vendor/datatables/media/images/sort_asc_disabled.png") !important;
}
table.dataTable thead .sorting_desc_disabled {
    background-image: url("../js/vendor/datatables/media/images/sort_desc_disabled.png") !important;
}
table.dataTable {
    //margin: 15px 0 10px !important;

    .odd,
    .even {
        td {
            text-align: left !important;
            padding-top: 8px !important;
            padding-bottom: 10px !important;
        }
        td {
            //white-space: nowrap;
        }
    }

    td[colspan] {
        padding-left: 28px !important;
    }
}
.dataTables_wrapper {
    margin-top: 25px !important;
    //margin-bottom: 25px !important;
}
.dataTables_scroll {
    margin: 15px 0 10px !important;
}

// Pagination buttons
.dataTables_wrapper .dataTables_paginate {
    background-color: transparent;

    > ul {
        margin-top: 3px;
        display: inline-block;
        overflow: visible;
        padding: 0;
    }

    li.paginate_button {
        margin: 0 !important;
        padding: 0 !important;
        border: 0 none !important;
        position: relative !important;

        &:hover {
            background: none;
            border-color: transparent;
        }

        a {
            min-width: 41px !important;
            //background-color: #bdc3c7;

            &:hover {
                background-color: $brand-primary;
            }
        }
        &.disabled a {
            //background-color: #e5e5e5;
        }
        &.active a {
            background-color: $brand-primary;

            &:hover {
                background-color: $brand-primary-darker;
            }
        }
    }
}
//.dataTables_wrapper .dataTables_paginate > ul {
//    margin-top: 3px;
//    display: inline-block;
//    overflow: visible;
//    padding: 0;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button {
//    margin: 0 !important;
//    padding: 0 !important;
//    border: 0 none !important;
//    position: relative !important;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button:hover {
//    background: none;
//    border-color: transparent;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button a {
//    min-width: 41px !important;
//    //background-color: #bdc3c7;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button a:hover {
//    background-color: $brand-primary;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button.disabled a {
//    //background-color: #e5e5e5;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button.active a {
//    background-color: $brand-primary;
//}
//.dataTables_wrapper .dataTables_paginate li.paginate_button.active a:hover {
//    background-color: $brand-primary-darker;
//}

.dataTables_paginate {
    .pagination > .active {
        > a,
        > a:hover,
        > a:focus,
        > span,
        > span:hover,
        > span:focus {
            border-color: #ddd;
        }
    }
    .pagination {
        > li > a,
        > li > span {
            &:hover,
            &:focus {
                color: #fff;
                //background-color: $pagination-hover-bg;
                //border-color: $pagination-hover-border;
            }
        }
        > .disabled {
            > span,
            > span:hover,
            > span:focus,
            > a,
            > a:hover,
            > a:focus {
                color: $pagination-disabled-color !important;
                background-color: $pagination-disabled-bg !important;
                //border-color: $pagination-disabled-border;
                //cursor: $cursor-disabled;
            }
        }
    }
}

.dataTable .row {
    margin-right: 0;

    div:nth-of-type(2) {
        border-top: 1px solid #ddd;
        margin-top: 15px;
        padding-top: 10px;

        @media (min-width: $screen-sm-min) {
            border-top: 0 none;
            margin-top: 0;
            padding-top: 0;
        }
    }

    th {
        vertical-align: top;
    }
    td {
        text-align: left;
    }
}

.paragraph-below-dataTable {
    margin-top: 25px;
}

.employee-status-change-hint {
    margin-top: 5px;
    margin-bottom: 0;
}
