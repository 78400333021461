@import "base";


//#logo-container {
//    @media (min-width: $screen-sm-min) {
//        text-align: center;
//    }
//    margin-bottom: 20px;
//
//    span {
//        font-size: $font-size-small - 2px;
//    }
//}
//#bds-logo {
//    width: 100%;
//    max-width: 180px;
//}

//#logo-container-test {
//    position: absolute;
//    top: 0;
//    left: 0;
//    //background-color: #ccc;
//    width: 0;
//    height: 0;
//    border-top: 200px solid #ccc;
//    border-right: 200px solid transparent;
//}
#logo-background {
    position: absolute;
    background-color: $gray-light;
    top: 0;
    left: 0;
    width: 78px;
    height: 58px;
    //transform: rotate(0deg);
    //transform-origin: 0 100% 0;
    //box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    //display: none;

    @media (min-width: $screen-sm-min) {
        top: 200px;
        left: -95px;
        width: 600px;
        height: 200px;
        transform: rotate(-56deg);
        transform-origin: 0 100% 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        //display: block;
    }

}
#logo-container {
    display: block;
    width: 70px;
    height: 48px;
    position: absolute;
    top: 5px;
    left: floor($grid-gutter-width / 2);
    z-index: 1001;
    background: transparent url("../images/logo-bd-no-text.svg") no-repeat;
    //margin: 10px 20px 20px floor($grid-gutter-width / 2);

    @media (min-width: $screen-sm-min) {
        //margin: 0;
        background-image: url("../images/logo-bd-with-text.svg");
        width: 100px;
        height: 74px;
        top: 10px;
        left: 10px;
    }
}
/*
#bds-logo {
    width: 68px;

    @media (min-width: $screen-sm-min) {
        width: 100px;
    }
}
*/
.merz-logo-container {
    text-align: center;

    img {
        //width: 64px;
    }
}

.gravatarNavImage,
.language-icon {
    float: left;
    position: relative;
    top: -7px;
    margin-right: 10px;
    //-moz-border-radius: 50%;
    //-webkit-border-radius: 50%;
    border-radius: 50%;
}

.alert ul {
    margin-top: $line-height-computed;
}

#account-home-links,
#account-home-admin-links {
    margin-top: $line-height-computed;

    a {
        background-color: $gray-light;
        border-color: darken($list-group-border, 10%);

        &:hover {
            //background-color: $brand-primary;
            background-color: $panel-default-heading-bg;
        }
    }
}


.breadcrumb {
    margin-top: -20px;
}


legend {
    padding-top: floor($line-height-computed / 4);
}

.short-form-field {
    width: 50%;
}

.formFieldError {
    font-weight: bold;
}

.button-container {
    // Wrapped buttons shouldn't touch each other
    line-height: 45px;
}

.terms-box {
    height: 115px;
    overflow: auto;
}


.license-keys-download-link,
.customers-download-link {
    text-align: right;
    margin: -10px 0 20px 0;
}
.customers-download-link {
    margin-top: 15px;
}


.customer-info-link {
    cursor: pointer;
}


#software-download-page {
    dl {
        padding-top: 10px;
    }

    dl:nth-of-type(n+2) {
        border-top: 1px solid $gray;
    }
}

.software-download-data {
    @media (min-width: $screen-sm-min) {
        dt {
            width: 230px;
        }
        dd {
            margin-left: 250px;
        }
    }
}
.customer-info-popover dl {
    @media (min-width: $screen-sm-min) {
        dt {
            width: 80px;
        }
        dd {
            margin-left: 85px;
        }
        dt, dd {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
/*
.software-download-data,
.customer-info-popover dl,
.main-license-info {
    @media (max-width: $screen-sm) {
        dt {
            width: auto;
        }
        dd {
            margin-left: 0;
        }
    }
}
*/

.dl-addressdata,
.main-license-info {
    @media (min-width: $screen-sm-min) {
        dt {
            width: 50px;
        }
        dd {
            margin-left: 70px;
        }
        dt, dd {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.dl-documentation-downloads {
    img {
        border: 1px solid $panel-default-border;
        margin: 4px 0;
    }
    @media (min-width: $screen-sm-min) {
        dt {
            width: 80px;
        }
        dd {
            margin-left: 100px;
            padding-top: 5%;
            padding-bottom: 5%;
            height: 110px;
            //vertical-align: middle;

            a {
                vertical-align: middle;
            }
        }
        img {
            margin: 0;
        }
    }
}

.customer-info-popover {
    height: 235px;
    overflow: auto;
}

.hr-support {
    margin-top: floor($line-height-computed * 1.5);
}
.support-addresses {
    margin-top: $line-height-computed;
}


#pageFooter {
    border-top: 1px solid #666;
    margin-top: 15px;
    margin-bottom: 10px;
    padding-top: 2px;

    small {
        font-size: 9px;
    }
    a {
        color: $gray-dark;
    }
}
#footer-links-container {
    margin-top: 5px;

    @media (min-width: $screen-sm-min) {
        margin-top: 0;
        text-align: right;
    }
}

// See http://getbootstrap.com/css/#tables-responsive
//@-moz-document url-prefix() {
//    fieldset { display: table-cell; }
//}


// Change some font sizes etc. for smartphones
@media (max-width: $screen-sm) {
    body {
        font-size: $font-size-large;  // + 1px?
    }

    dd {
        margin-bottom: ceil($line-height-computed / 2);
    }

    legend {
        font-size: $font-size-large + 5px;
        padding-top: $line-height-computed;
    }
    /*
    fieldset:first-child legend,
    input[type="hidden"] + fieldset legend{
       padding-top: 0;
    }
    */

    .form-control {
        font-size: $font-size-base + 1px;
    }

    .radio input[type="radio"],
    .radio-inline input[type="radio"],
    .checkbox input[type="checkbox"],
    .checkbox-inline input[type="checkbox"] {
        transform: scale(1.5);
    }
    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
        margin-left: -25px;
    }
    .radio,
    .checkbox {
        label {
            padding-left: 28px;
        }
    }

    .button-container {
        // Wrapped buttons shouldn't touch each other
        // Here the buttons are bigger, so the line-height has to be adapted, too
        line-height: 50px;
    }
    .btn {
        font-size: $font-size-large;
        white-space: normal;
    }
    .btn-lg {
        font-size: $font-size-large + 2px;
    }

    .navbar-nav > li {
        & > a,
        .dropdown-menu > li > a {
            font-size: $font-size-base + 8px !important;
            line-height: $line-height-computed + 4px;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }
        .dropdown-menu > li > a {
            padding-left: 35px !important;
        }
    }

    #pageFooter {
        small {
            font-size: $font-size-base;
        }

        #footer-links-container {
            li {
                line-height: $line-height-computed + 20;
            }
            a {
                text-decoration: underline;
            }
        }
    }
}
