legend {
    font-family: $font-family-specials;
    letter-spacing: 1px;
}

input {
    font-family: $font-family-monospace;

    &[type="submit"],
    &[type="reset"],
    &[type="button"] {
        font-family: $font-family-sans-serif;
    }
}

.help-block {
    color: lighten($text-color, 25%) !important;  // lighten the text some for contrast
}


label.control-label.required:after {
    content: ' *';
}
