.btn {
    padding-bottom: $padding-base-vertical + 2px;
}
.btn-lg {
    padding-bottom: $padding-large-vertical + 2px;
}
.btn-sm {
    padding-bottom: $padding-small-vertical + 2px;
}
.btn-xs {
    padding-bottom: $padding-xs-vertical + 2px;
}
