.panel-default > .panel-heading {
    color: darken($gray-dark, 10%);
}

.panel-body h2:first-child {
    margin-top: 5px;
}

.panel hr {
    border-color: $panel-default-border;
}
